<template>
  <div class="page-login-desktop">
    <v-card flat>
      <v-card-title class="text-body-1 font-weight-bold d-flex align-center justify-space-between">
        ایجاد کاربر جدید
        <v-btn @click="$router.back()" text color="main" large>
          بازگشت
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>

          <v-col :cols="12" :lg="4">
            <v-text-field outlined label="نام و نام خانوادگی" hide-details v-model="admin.Nameandfamily"/>
          </v-col>

          <v-col :cols="12" :lg="4">
            <v-text-field outlined label="شماره موبایل" hide-details v-model="admin.mobile"/>
          </v-col>

          <v-col :cols="12" :lg="4">
            <v-select outlined label="نوع کاربر" hide-details v-model="admin.accountType" :items="adminTypes"
                      item-value="id" item-text="name"/>
          </v-col>

          <v-col v-if="admin.accountType === 2" :cols="12" :lg="4">
            <v-text-field outlined label="شماره ثابت تماس" hide-details v-model="admin.phone"/>
          </v-col>

          <v-col v-if="admin.accountType === 1" :cols="12" :lg="4">
            <v-text-field outlined label="شماره مدیر املاک" hide-details v-model="admin.ParentMobile"/>
          </v-col>

          <v-col :cols="12" :lg="4">
            <v-text-field outlined label="تعداد حساب محاز" hide-details v-model="admin.LimitCount"/>
          </v-col>

          <v-col v-if="admin.accountType === 2" :cols="12" :lg="4">
            <v-text-field outlined label="نام آژانس" hide-details v-model="admin.Agencyname"/>
          </v-col>

          <v-col v-if="admin.accountType === 2" :cols="12" :lg="4">
            <v-text-field outlined label="تعداد زیر مجموعه مجاز" hide-details v-model="admin.Numberofaccountsallowed"/>
          </v-col>

          <v-col v-if="admin.accountType === 2" :cols="12" :lg="4">
            <v-combobox
                :items="locations"
                item-text="name"
                item-value="id"
                outlined
                label="محله"
                hide-details
                v-model="admin.Neighborhoodcode"/>
          </v-col>

          <v-col v-if="admin.accountType === 2" :cols="12" :lg="6">
            <v-text-field outlined label="آدرس" hide-details v-model="admin.Adress"/>
          </v-col>
          <v-col v-if="admin.accountType === 2" :cols="12" :lg="3">
            <v-switch inset label="وضعیت فعال بودن کاربر" hide-details v-model="admin.Userstatus"/>
          </v-col>
          <v-col v-if="admin.accountType === 2" :cols="12" :lg="3">
            <v-switch inset label="دسترسی به نرم افزار ویندوز" hide-details v-model="admin.AccesstoWindowssoftware"/>
          </v-col>
          <v-col v-if="admin.accountType === 2" :cols="12" :lg="3">
            <v-switch inset label="دسترسی به نرم افزار موبایل" hide-details v-model="admin.Mobileaccess"/>
          </v-col>
          <v-col v-if="admin.accountType === 2" :cols="12" :lg="3">
            <v-switch inset label="دسترسی به سایت" hide-details v-model="admin.Siteaccess"/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="admin.loading" @click="() => {
            admin.accountType === 1
              ? adminCreaterealestateconsultant()
              : adminCreatepropertymanager()

        }" elevation="0" large color="main" class="white--text">
          ثبت اطلاعات
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {adminCreatepropertymanager, adminCreaterealestateconsultant} from "@Newfiling/module-user/src/api";
import {AppConfig} from "@Newfiling/Services";

export default {
  name: 'DesktopLogin',

  data() {
    return {
      locations: [],
      admin: {
        LimitCount:2,
        accountType: null,
        loading: false,
        mobile: "",
        Nameandfamily: "",
        Adress: "",
        Agencyname: "",
        Neighborhoodcode: "",
        phone: null,
        Userstatus: false,
        Toprealestate: false,
        AccesstoWindowssoftware: false,
        Mobileaccess: false,
        Siteaccess: false,
        Numberofaccountsallowed: null,
        ParentMobile: null,
      },


      adminTypes: [
        {
          id: 1,
          name: 'مشاور املاک'
        },
        {
          id: 2,
          name: 'مدیر املاک'
        },
      ],
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست کاربران',
        disabled: false,
        href: '/panel/users/list',
      },
      {
        text: 'کاربر جدید',
        disabled: true,
        href: '#',
      },
    ])
  },


  mounted() {
    this.$nextTick(async () => {
      const appConfig = (await AppConfig) || {};
      this.locations = appConfig.neighborhoods.map(item => {
        return {
          name: item.NeighborhoodName,
          id: item.Id
        }
      });
    });
  },

  methods: {
    async adminCreatepropertymanager() {
      this.admin.loading = true;
      try {
        await adminCreatepropertymanager({
          mobile: this.admin.mobile,
          Nameandfamily: this.admin.Nameandfamily,
          Adress: this.admin.Adress,
          Agencyname: this.admin.Agencyname,
          Neighborhoodcode: this.admin.Neighborhoodcode?.id,
          phone: this.admin.phone,
          Userstatus: this.admin.Userstatus,
          Toprealestate: this.admin.Toprealestate,
          AccesstoWindowssoftware: this.admin.AccesstoWindowssoftware,
          Mobileaccess: this.admin.Mobileaccess,
          Siteaccess: this.admin.Siteaccess,
          Numberofaccountsallowed: this.admin.Numberofaccountsallowed,
          LimitCount: this.admin.LimitCount,
        })
        this.$toast.success('کاربر با موفقیت اضافه شد.');
        await this.$router.replace('/panel/users/list')
      } catch (e) {
        console.log(e)
      }
      this.admin.loading = false
    },
    async adminCreaterealestateconsultant() {
      this.admin.loading = true;
      try {
        await adminCreaterealestateconsultant({
          ParentMobile: this.admin.ParentMobile,
          Nameandfamily: this.admin.Nameandfamily,
          mobile: this.admin.mobile,
          LimitCount: this.admin.LimitCount,
        })
        this.$toast.success('کاربر با موفقیت اضافه شد.');
        await this.$router.replace('/panel/users/list')
      } catch (e) {
        console.log(e)
      }
      this.admin.loading = false
    },
  },
}
</script>
